.custom-container {
  background-color: black;
}

.d-flex {
  display: flex; /* Enable Flexbox layout */
}

.nav-wrapper {
  display: flex; /* Enable Flexbox layout */
  justify-content: center;
}

.navbar {
  padding: 0; /* Remove default navbar padding */
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: center;
}

.navbar .nav-link {
  color: rgb(190, 190, 190); /* Example: Set a contrasting color */
  font-weight: bold;
}
.navbar-light .navbar-nav .nav-link.active {
  color: rgb(255, 255, 255);
}

.navbar .nav-link:hover {
  color: #ffffff; /* Change to your desired hover color */
}
