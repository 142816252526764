img {
  width: 100%;
}

/* Showcase Styling */

.showcase {
  width: 100%;
  height: 60vh;
  position: relative;
  background: url("https://github.com/sjvarnum/acme-media-showcase/blob/master/img/showcase.jpg?raw=true")
    no-repeat center center/cover;
}

/* Showcase Overlay */

.showcase::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: inset 120px 100px 250px #000000, inset -120px -100px 250px #000000;
}

.showcase-top {
  position: relative;
  height: 90px;
  z-index: 2;
}

.showcase-top .logo-text {
  color: var(--primary-color);
  font-family: "Roboto", sans-serif;
  --webkit-font-smoothing: antialiased;
  font-size: 38px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.showcase-top a {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.showcase-content {
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 9rem;
  z-index: 2;
}

.showcase-content h1 {
  font-weight: 700;
  font-size: 5.2rem;
  line-height: 1.1rem;
  margin: 0 0 2rem;
}

.showcase-content p {
  font-weight: 400;
  color: #fff;
}
